import React from "react";
import ClientLayout from "./layout/ClientLayout";
import HeroSlider from "./page/HeroSlider";
import Achievement from "./page/Achievement";
import BestSell from "./page/bestsell";
import Business from "./page/business";
import PacksBanner from "./page/packs_banner";
import TopCategories from "./page/TopCategories";

function LandingPage() {
  return (
    <ClientLayout>
      <HeroSlider />
      <Achievement />
      <BestSell />
      <TopCategories />
      {/* <Tradeprint /> */}
      {/* <Business /> */}
      {/*  <PacksBanner /> */}
    </ClientLayout>
  );
}

export default LandingPage;
