import React, { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import {
  getAllProductsQuery,
  getTopListingProductQuery,
} from "../../resolvers/query";
import Loader from "../Loader/Loader";
import EmblaCarousel from "./Carousel/EmblaCarousel";

const BestSell = () => {
  const [activeTab, setActiveTab] = useState(null);

  const OPTIONS = { align: "start" };

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["best-sell"],
    queryFn: getTopListingProductQuery,
  });

  const tabList = data?.data?.map((item) => ({
    id: item.id,
    label: item.heading,
  }));

  useEffect(() => {
    if (data?.data) {
      if (tabList?.length > 0) {
        setActiveTab(tabList[0].id);
      }
    }
  }, [data?.data]);
  return (
    <>
      <div className="bg-[#F0F2F2]">
        <div className="py-5 custom_container ">
          <div className="col-span-12 md:col-span-6 xxl:!col-span-4">
            <div className="py-10 ">
              <div className="text-center ">
                <h5 className="mb-2 text-xl font-bold box-title text-secondgraphy md:text-2xl lg:text-3xl">
                  Custom Printed Best Sellers
                </h5>
              </div>
              <div className="box-body mt-[15px]">
                <div className="border-b dark:border-white/10">
                  <nav
                    className="-mb-0.5 flex justify-center space-x-6 rtl:space-x-reverse overflow-x-auto"
                    aria-label="Tabs"
                  >
                    {tabList?.map((tab, index) => (
                      <button
                        onClick={() => setActiveTab(tab.id)}
                        type="button"
                        key={index}
                        className={`  border-b-2   pb-1 pt-4 px-1 inline-flex items-center gap-2    whitespace-nowrap text-defaulttextcolor   active ${
                          activeTab === tab.id
                            ? "text-secondgraphy border-secondgraphy font-semibold"
                            : " "
                        }`}
                        id="horizontal-alignment-item-1"
                        data-hs-tab="#horizontal-alignment-1"
                        aria-controls="horizontal-alignment-1"
                      >
                        {tab.label}
                      </button>
                    ))}
                  </nav>
                </div>

                <div className="mt-3">
                  {isLoading ? (
                    <div>
                      <Loader />
                    </div>
                  ) : isError ? (
                    <div>Something went wrong!</div>
                  ) : data?.data?.find(
                      (topListing) => topListing.id === activeTab
                    )?.top_listing_items?.length > 0 ? (
                    <EmblaCarousel
                      slides={
                        data?.data?.find(
                          (topListing) => topListing.id === activeTab
                        )?.top_listing_items
                      }
                      options={OPTIONS}
                    />
                  ) : (
                    <div className="text-center text-defaulttextcolor">
                      <p>No product found</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BestSell;
