import React from "react";
import { getMediaThumbURL } from "../../../lib/utils";

const TopCategoryItem = ({ category }) => {
  return (
    <a
      href={`/product/${category.slug}`}
      className="relative w-full overflow-hidden transition-all duration-500 ease-in-out rounded-lg group hover:shadow-md "
    >
      <img
        className="object-cover object-center w-full h-full transition-all duration-500 ease-in-out transform group-hover:scale-110"
        src={getMediaThumbURL(category.media)}
        alt="busness"
        loading="eager"
      />

      <div className="absolute bottom-0 flex items-center justify-center w-full h-10 text-center text-white bg-black/60">
        <h6 className="w-full mx-auto text-sm font-bold text-center ">
          {category.title}
        </h6>
      </div>
    </a>
  );
};

export default TopCategoryItem;
